<template>
	<div>
		<Header :title="isEdit ? '修改影院信息' : '新增影院信息'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
		<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">
			<a-row>
				<a-col :span="12">

          <div>
            <h3 class="ui-title">基本信息</h3>
            <a-form-item label="影院名称" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-input v-model:value="modelRef.name" placeholder="请输入影院名称"></a-input>
            </a-form-item>
            <a-form-item label="影院地址" name="address" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-input v-model:value="modelRef.address" placeholder="请输入影院地址"></a-input>
            </a-form-item>
            <a-form-item label="经纬度" name="coordinate" :rules="[{required: true, message: '必填项不允许为空'}]" extra="用于小程序定位信息">
              <div class="ui-custom__btnInput">
                <a-input v-model:value="modelRef.coordinate" placeholder="请输入经纬度"></a-input>
                <a-button :disabled="isSee" class="ui-custom__btn" type="primary" @click="onOpenWin">坐标拾取器</a-button>
              </div>
            </a-form-item>
            <a-form-item label="影院电话" name="contactPhone" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-input v-model:value="modelRef.contactPhone" placeholder="请输入影院电话"></a-input>
            </a-form-item>
            <a-form-item label="影院国家编码" name="code" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-input v-model:value="modelRef.code" placeholder="请输入影院国家编码"></a-input>
            </a-form-item>
            <a-form-item name="tagIdList" label="影院标签" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-select mode="multiple" v-model:value="modelRef.tagIdList" placeholder="请输入影院标签">
                <a-select-option
                    :value="item.id"
                    v-for="item in tagList"
                    :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>

          <div>
            <h3 class="ui-title">配置信息</h3>
            <a-form-item label="营业时间" name="serviceTime">
              <a-input v-model:value="modelRef.serviceTime" placeholder="请输入营业时间"></a-input>
            </a-form-item>
            <a-form-item label="停车信息" name="trafficInfo">
              <a-input v-model:value="modelRef.trafficInfo" placeholder="请输入停车信息"></a-input>
            </a-form-item>
            <a-form-item label="影城公告" name="announcement">
              <a-textarea :autoSize="{ minRows: 5, maxRows: 10 }" v-model:value="modelRef.announcement"></a-textarea>
            </a-form-item>
            <a-form-item label="影院简介" name="cinemaDesc">
              <a-textarea :autoSize="{ minRows: 5, maxRows: 10 }" v-model:value="modelRef.cinemaDesc"></a-textarea>
            </a-form-item>
          </div>
				</a-col>

				<a-col :span="12">
          <div>
            <h3 class="ui-title">票务信息</h3>
            <a-form-item label="延迟销售时间">
              <div>{{ modelRef.saleDelayTime }}分钟</div>
            </a-form-item>
            <a-form-item label="最大票数">
              <div>{{ modelRef.saleMaxTickets }}</div>
            </a-form-item>
            <div style="margin-left: 80px;color: #c2c2c2" class="ui-form__tip">以上信息来源于票务系统</div>
            <a-form-item style="margin-top: 30px;" label="网络代售费" name="serviceCharge" :rules="[{required: true, message: '必填项不允许为空'}]" extra="用户购票时收取，按座位为单位收取服务费，修改后需要同步定价政策">
              <a-input-number style="width: 200px;" v-model:value="modelRef.serviceCharge" placeholder="请输入网络代售费"></a-input-number>
            </a-form-item>
            <a-form-item style="margin-top: 30px;" label="影票手续费" name="ticketFee" :rules="[{required: true, message: '必填项不允许为空'}]" extra="不收取用户费用，仅作用于财务表统计">
              <a-input-number style="width: 200px;" v-model:value="modelRef.ticketFee" placeholder="请输入影票手续费"></a-input-number>
            </a-form-item>
            <a-form-item style="margin-top: 30px;" label="商品&卖品手续费" name="otherFee" :rules="[{required: true, message: '必填项不允许为空'}]" extra="不收取用户费用，仅作用于财务表统计">
              <a-input-number style="width: 200px;" v-model:value="modelRef.otherFee" placeholder="请输入影城&卖品手续费"></a-input-number> %
            </a-form-item>
          </div>

          <div>
            <h3 class="ui-title">图片信息</h3>
            <a-form-item label="影院图片">
              <a-upload
                  action="/admin/ajaxUpload.do"
                  v-model:file-list="fileList"
                  accept="image/*"
                  @change="onChange"
              >
                <a-button :disabled="modelRef.adImageList.length > 2">
                  <!-- <upload-outlined></upload-outlined> -->
                  上传图片
                </a-button>
              </a-upload>
              <div style="color: #c2c2c2">建议图片比例：375 : 227</div>
              <div style="color: #c2c2c2">最多可上传3张图片</div>
            </a-form-item>
            <a-list style="width: 300px; margin-left: 50px;" bordered>
              <a-list-item v-for="(file, index) in modelRef.adImageList" :key="index">
                <a-image style="width: 100px;" :src="file"></a-image>
                <a-button-group>
                  <a-button :disabled="index === 0" @click="moveUp(index)" size="large">
                    <template #icon><UpOutlined /></template>
                  </a-button>
                  <a-button :disabled="index === modelRef.adImageList.length - 1" size="large" @click="moveDown(index)">
                    <template #icon><DownOutlined /></template>
                  </a-button>
                  <a-button type="primary" size="large" @click="deleteImg(index)">
                    <template #icon><CloseOutlined /></template>
                  </a-button>
                </a-button-group>
              </a-list-item>
            </a-list>
          </div>
				</a-col>
			</a-row>

			<a-row>
				<a-col :span="24" style="margin-top: 20px;text-align: center;">
					<a-button v-if="!isSee" type="primary" html-type="submit" @click="onSubmit">提交</a-button>
					<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
				</a-col>
			</a-row>
		</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
  import { UpOutlined,DownOutlined,CloseOutlined } from '@ant-design/icons-vue';
  import {getCinemaDetail, getCinemaTagAllList, updateCinema} from "@/service/modules/cinema";
	export default {
		components: { Header, UpOutlined, DownOutlined, CloseOutlined },
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		created() {
			if(this.isEdit && this.id) {
				this.getData();
        this.getCinemaTag();
			}
		},
		data() {
			return {
        tagIds: [],
        tagList: [],
        fileList: [],
        imgUrl: '',
        coordinate: '',
				loading: false,
				modelRef: {
          label: 0,
          adImageList: [],
				},
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
      async getData() {
        this.loading = true;
        try {
          let ret = await getCinemaDetail({
            id: this.id
          })
          this.loading = false;
          if (ret.code === 200) {
            ret.data.coordinate = ret.data.ycoordinate + ',' + ret.data.xcoordinate;
            if(ret.data.coordinate === ',') ret.data.coordinate = null;
            this.modelRef = ret.data;
          }
        } catch(e) {
          this.loading = false;
        }
      },
      // 获取全部影院标签
      async getCinemaTag() {
        this.loading = true;
        try {
          let ret = await getCinemaTagAllList({
            isDisabled: 0
          })
          this.loading = false;
          if(ret.code === 200) {
            this.tagList = ret.data;
            this.checkTag();
          }
        } catch(e) {
          this.loading = false;
        }
      },
      checkTag() {
        if(this.tagList.length && this.modelRef.tagIdList.length) {
          for(let i = 0; i < this.modelRef.tagIdList.length; i++) {
            for(let j = 0; j < this.tagList.length; j++) {
              if(this.modelRef.tagIdList[i] === this.tagList[j].id) {
                this.tagIds.push(this.tagList[j].id)
              }
            }
          }
          this.modelRef.tagIdList = this.tagIds;
        };
      },
      onSubmit() {
        this.$refs.form.validateFields().then(() => {
          this.$confirm({
            title: '提示',
            content: '确认要提交并保存吗？',
            onOk: async () => {
            this.modelRef.ycoordinate = this.modelRef.coordinate.split(',')[0];
            this.modelRef.xcoordinate = this.modelRef.coordinate.split(',')[1];
            let postData = JSON.parse(JSON.stringify(this.modelRef));
            try {
              let ret;
              this.loading = true;
              ret = await updateCinema(postData);
              this.loading = false;
              if(ret.code === 200) {
                this.$message.success('操作成功');
                this.onBack(true);
              }
            } catch(e) {
              this.loading = false;
            }
            }
          })
        })
      },
      onOpenWin() {
        window.open('https://lbs.qq.com/getPoint/');
      },
      onChange(info) {
        if (info.file.status === 'done') {
          this.modelRef.adImageList.push(info.file.response.data.imgUrl);
          this.fileList = info.fileList;
          const $fileList = [...this.fileList];
          this.fileList = $fileList.map(file => {
            if (file.response) {
              file.url = file.response.data.imgUrl;
            }
            return file;
          });
        }
      },
      // 上移
      moveUp(index){
        const temp = this.modelRef.adImageList[index];
        this.modelRef.adImageList.splice(index, 1);
        this.modelRef.adImageList.splice(index - 1, 0, temp);
      },
      // 下移
      moveDown(index){
        const temp = this.modelRef.adImageList[index];
        this.modelRef.adImageList.splice(index, 1);
        this.modelRef.adImageList.splice(index + 1, 0, temp);
      },
      // 删除指定图片
      deleteImg(index){
        this.modelRef.adImageList.splice(index, 1);
      }
		}
	}
</script>

<style scoped>
.ui-title {
  position: relative;
  margin: 20px 0 10px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 600;
}

.ui-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 4px;
  height: 20px;
  border-radius: 2px;
  background-color: #1890ff;
  transform: translateY(-50%);
}

.ui-custom__btnInput {
  position: relative;
}
.ui-custom__btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
</style>
