<template>
  <div>
    <a-spin :spinning="loading">
      <Header style="margin-bottom: 20px;" title="影厅列表" @back="onBackHall(false)"></Header>
      <a-spin :spinning="loading">
        <a-form ref="form" :model="formState" name="form" @finish="onSearch">
          <!-- 搜索栏 -->
          <a-row>

            <a-form-item class="ui-form__item" name="name" label="影厅名称">
              <a-input v-model:value="formState.name" placeholder="请输入影厅名称" />
            </a-form-item>

            <a-form-item class="ui-form__item" name="code" label="影厅编号">
              <a-input v-model:value="formState.code" placeholder="请输入影厅编号" />
            </a-form-item>

            <a-form-item class="ui-form__item" name="type" label="影厅类型">
              <a-select v-model:value="formState.type" allow-clear style="width: 180px;" placeholder="请选择影厅类型">
                <a-select-option :value="'NORMAL'">普通</a-select-option>
                <a-select-option :value="'3D'">普通3D</a-select-option>
                <a-select-option :value="'IMAX'">IMAX</a-select-option>
                <a-select-option :value="'LED'">LED</a-select-option>
                <a-select-option :value="'CINTY'">cinty</a-select-option>
                <a-select-option :value="'MAX'">巨幕</a-select-option>
                <a-select-option :value="'VIP'">VIP</a-select-option>
                <a-select-option :value="'DOLBY'">全景声</a-select-option>
                <a-select-option :value="'DTS'">临景音影厅</a-select-option>
                <a-select-option :value="'LOVER'">情侣厅</a-select-option>
              </a-select>
            </a-form-item>

          </a-row>

          <a-row>
            <a-col :span="24" style="text-align: right">
              <a-button
                  style="margin-right: 10px"
                  type="primary"
                  html-type="submit"
              >搜索</a-button
              >
              <a-button @click="reset">重置</a-button>
            </a-col>
          </a-row>
        </a-form>

        <div style="margin-top: 20px;">
          <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                   :scroll="{ x: 950 }">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'type'">
                <div v-if="record.type === 'NORMAL'">普通</div>
                <div v-if="record.type === '3D'">普通3D</div>
                <div v-if="record.type === 'IMAX'">IMAX</div>
                <div v-if="record.type === 'LED'">LED</div>
                <div v-if="record.type === 'CINTY'">cinty</div>
                <div v-if="record.type === 'MAX'">巨幕</div>
                <div v-if="record.type === 'VIP'">VIP</div>
                <div v-if="record.type === 'DOLBY'">全景声</div>
                <div v-if="record.type === 'DTS'">临景音影厅</div>
                <div v-if="record.type === 'LOVER'">情侣厅</div>
              </template>
              <template v-if="column.key === 'action'">
                <a-dropdown :trigger="['click', 'hover']">
                  <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <div>
                        <a-menu-item @click="onShowTypeModal(record)">
                          选择影厅类型
                        </a-menu-item>
                      </div>
                      <div>
                        <a-menu-item @click="toSee(record)">
                          查看场区
                        </a-menu-item>
                      </div>
                    </a-menu>
                  </template>
                </a-dropdown>
              </template>
            </template>
          </a-table>
        </div>

        <a-modal v-model:visible="showTypeModal" destroyOnClose title="选择影厅类型" width="500px" @ok="onSubmit">
          <a-spin :spinning="loading">
            <a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">
              <a-form-item label="影厅类型" name="type">
                <a-select v-model:value="modelRef.type">
                  <a-select-option :value="'NORMAL'">普通</a-select-option>
                  <a-select-option :value="'3D'">普通3D</a-select-option>
                  <a-select-option :value="'IMAX'">IMAX</a-select-option>
                  <a-select-option :value="'LED'">LED</a-select-option>
                  <a-select-option :value="'CINTY'">cinty</a-select-option>
                  <a-select-option :value="'MAX'">巨幕</a-select-option>
                  <a-select-option :value="'VIP'">VIP</a-select-option>
                  <a-select-option :value="'DOLBY'">全景声</a-select-option>
                  <a-select-option :value="'DTS'">临景音影厅</a-select-option>
                  <a-select-option :value="'LOVER'">情侣厅</a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </a-spin>
        </a-modal>

        <a-modal v-model:visible="showModal" destroyOnClose title="查看场区" width="500px" @ok="onSeeSubmit">
          <div>
            <a-radio-group v-model:value="areaId">
              <a-radio v-for="item in areaList" :key="item.id" :value="item.id">{{ item.name }} ({{ item.code ? item.code : '-' }})</a-radio>
            </a-radio-group>
          </div>
        </a-modal>

      </a-spin>
    </a-spin>
  </div>
</template>

<script>
import Header from '@/components/header/header.vue';
import {
  Icon
} from '@/components/icon/icon.js';
import {getHallList, updateHall, getAreaList} from "@/service/modules/cinema";
export default {
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  components: {
    Icon,
    Header
  },
  data() {
    return {
      areaId: 0,
      hallId: 0,
      flag: 0,
      hallType: '',
      showAreaModal: false,
      areaList: [],
      showTypeModal: false,
      showModal: false,
      loading: false,
      showAll: false,
      modelRef: {
        type: 'NORMAL'
      },
      formState: {},
      searchData: {},
      columns: [{
        title: '影厅编号',
        dataIndex: 'code',
        width: 100
      }, {
        title: '影厅名称',
        dataIndex: 'name',
        width: 150
      }, {
        title: '座位数量',
        dataIndex: 'seatCount',
        width: 60
      }, {
        title: '影厅类型',
        key: 'type',
        width: 100
      }, {
        title: '当前场区',
        dataIndex: 'areaName',
        width: 100
      }, {
        title: '操作',
        key: 'action',
        width: 100
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      // //this.pagination.pageSize = 10;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    toSee(item) {
      this.showModal = true;
      this.hallId = item.id;
      this.areaId = item.areaId;
      this.flag = item.areaId;
      this.getAreaList();
    },
    onBackHall(isRef) {
      this.$emit('back', isRef);
    },
    onShowTypeModal(item) {
      this.showTypeModal = true;
      this.hallId = item.id;
      item.type ? this.modelRef.type = item.type : this.modelRef.type = 'NORMAL';
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getHallList({
          cinemaId: this.id,
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async getAreaList() {
      this.loading = true;
      try {
        let ret = await getAreaList({
          hallId: this.hallId,
        })
        this.loading = false;
        if(ret.code === 200) {
          this.areaList = ret.data.list;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async onSeeSubmit() {
      if(this.flag === this.areaId) {
        this.showModal = false;
      } else {
        this.$confirm({
          title: '提示',
          content: '确认要切换场区吗？',
          onOk: async () => {
            this.loading = true;
            try {
              let ret = await updateHall({
                id: this.hallId,
                areaId: this.areaId,
                isDel: 0
              })
              this.loading = false;
              if(ret.code === 200) {
                this.$message.success('操作成功');
                this.getData();
                this.showModal = false;
                this.areaId = null;
              }
            } catch(e) {
              this.loading = false;
            }
          }
        })
      }
    },
    onSubmit() {
      this.$refs.addForm.validateFields().then(async () => {
        this.loading = true;
        try {
          let ret = await updateHall({
            id: this.hallId,
            type: this.modelRef.type
          })
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.$refs.addForm.resetFields();
            this.showTypeModal = false;
            this.getData();
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
