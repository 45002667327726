<template>
  <div>
    <Header style="margin-bottom: 20px;" title="关联用户" @back="onBackAssociation(false)"></Header>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item class="ui-form__item" name="adminUserName" label="账号">
            <a-input v-model:value="formState.adminUserName" placeholder="请输入账号" />
          </a-form-item>

          <a-form-item class="ui-form__item" name="adminUserNickname" label="昵称">
            <a-input v-model:value="formState.adminUserNickname" placeholder="请输入昵称" />
          </a-form-item>

          <a-form-item class="ui-form__item" name="isDisabled" label="状态">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button type="primary" @click="onAdd">新增关联用户</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 950 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'action'">
              <a-button type="primary" @click="onDelete(record)">移除</a-button>
            </template>
          </template>
        </a-table>
      </div>

      <a-modal v-model:visible="showPeople" :maskClosable="false" destroyOnClose title="新增负责人" width="750px" @ok="saveAdminUserCinema">
        <a-spin :spinning="loading">
          <a-form ref="peopleForm" :model="peopleFormState" name="peopleForm" @finish="onPeopleSearch">
            <a-row>
              <a-form-item class="ui-form__item" name="adminUserName" label="账号">
                <a-input v-model:value="peopleFormState.adminUserName" placeholder="请输入账号"></a-input>
              </a-form-item>

              <a-form-item class="ui-form__item" name="adminUserNickname" label="昵称">
                <a-input v-model:value="peopleFormState.adminUserNickname" placeholder="请输入昵称"></a-input>
              </a-form-item>
            </a-row>

            <a-row>
              <a-col :span="24" style="text-align: right;">
                <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
                <a-button @click="reset">重置</a-button>
              </a-col>
            </a-row>
          </a-form>
          <div style="margin-top: 20px;">
            <a-table :pagination="peoplePagination" :columns="peopleColumns" :dataSource="peopleList" rowKey="id"
                     :rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
                     :scroll="{ x: 450 }">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'action'">
                  <a-button @click="onSelectOne(record)" type="primary">选择</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-spin>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import Header from '@/components/header/header.vue';
import {deleteAdminUserCinema, getAdminUserCinemaList, saveAdminUserCinema} from "@/service/modules/cinema";
import {userList} from "@/service/modules/system";
export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
  },
  components: {
    Header
  },
  data() {
    return {
      rowSelectionType: 'checkbox',
      showPeople: false,
      showModal: false,
      loading: false,
      showAll: false,
      searchData: {},
      peopleSearchData: {},
      selectedRowKeys: [],
      formState: {},
      peopleFormState: {

      },
      peopleColumns: [{
        title: '账号',
        dataIndex: 'username',
        width: 100
      }, {
        title: '昵称',
        dataIndex: 'nickname',
        width: 100
      }, {
        title: '操作',
        key: 'action',
        width: 100
      }],
      peopleList: [],
      columns: [{
        title: '账号',
        dataIndex: 'adminUserName',
        width: 100
      }, {
        title: '昵称',
        dataIndex: 'adminUserNickname',
        width: 100
      }, {
        title: '小程序用户名',
        dataIndex: 'baseUserNickname',
        width: 100
      }, {
        title: '状态',
        key: 'isDisabled',
        width: 100
      }, {
        title: '操作',
        key: 'action',
        width: 100
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
      peoplePagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.peoplePagination.current = page;
          this.getUserList();
        },
        onShowSizeChange: (page, pageSize) => {
          this.peoplePagination.current = 1;
          this.peoplePagination.pageSize = pageSize;
          this.getUserList();
        }
      },
    }
  },
  created() {
    this.getData();
    this.getUserList();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      // //this.pagination.pageSize = 10;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    peopleReset() {
      this.$refs.peopleForm.resetFields();
      this.onPeopleSearch();
    },
    onPeopleSearch() {
      this.peoplePagination.current = 1;
      this.peopleSearchData = JSON.parse(JSON.stringify(this.peopleFormState));
      this.getData();
    },
    onAdd() {
      this.selectedRowKeys = [];
      this.list.forEach(item => {
        this.selectedRowKeys.push(item.adminUserId);
      })
      this.showPeople = true;
    },
    onSelectOne(item){
      if(this.selectedRowKeys.indexOf(item.id) === -1) {
        this.selectedRowKeys = [];
        this.selectedRowKeys.push(item.id);
        this.saveAdminUserCinema();
        this.showPeople = false;
      } else {
        this.$message.error('已关联该用户！');
      }
    },
    onSelectChange(record, selected) {
      if (this.rowSelectionType === 'checkbox') {
        if (selected) {
          // this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
          this.selectedRowKeys.push(record.id);
        } else {
          this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
          // this.selectedRowData = this.selectedRowData.filter(item => {
          // 	return item.id !== record.id;
          // });
        }
      }
    },
    onSelectAll(selected) {
      if (selected) {
        this.peopleList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) === -1) {
            // this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
            this.selectedRowKeys.push(item.id);
          }
        })
      } else {
        this.peopleList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) !== -1) {
            // this.selectedRowData.splice(this.selectedRowKeys.indexOf(item.id), 1);
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
          }
        })
      }
    },
    onDelete(item) {
      this.$confirm({
        content: '确定移除该用户吗',
        onOk: async () => {
          this.loading = true;
          let ret = await deleteAdminUserCinema({
            id: item.id
          });
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.pagination.current = 1;
            this.getData();
          }
        }
      })
    },
    onBackAssociation(isRef) {
      this.$emit('back', isRef);
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getAdminUserCinemaList({
          cinemaId: this.id,
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async saveAdminUserCinema() {
      if(this.selectedRowKeys.length === 0) {
        this.$message.warning('请选择负责人！');
        return;
      } else {
        let idList = [];
        this.list.forEach(item => {
          idList.push(item.adminUserId);
        })
        idList.forEach((item) => {
          if(this.selectedRowKeys.includes(item)) {
            this.selectedRowKeys = this.selectedRowKeys.filter(info => item !== info);
          }
        })
      }
      this.loading = true;
      try {
        let ret = await saveAdminUserCinema({
          cinemaId: this.id,
          adminUserIdList: this.selectedRowKeys
        })
        this.loading = false;
        if(ret.code === 200) {
          this.$message.success('操作成功');
          this.selectedRowKeys = [];
          this.getData();
          this.showPeople = false;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async getUserList() {
      this.loading = true;
      try {
        let ret = await userList({
          page: this.peoplePagination.current,
          pageSize: this.peoplePagination.pageSize,
          ...this.peopleSearchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.peopleList = ret.data.list;
          this.peoplePagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
